<template>
  <div v-html="data" v-if="data"></div>
  <div class="d-flex justify-content-center full-height full-width align-items-center" v-else>
    <div class="error-container text-center">
      <h2 class="semi-bold">{{msg}}</h2>
    </div>
  </div>
</template>

<script>
import {store} from "@store";

export default {
  name: "QrCode",
  data() {
    return {
      data: null,
      msg : null
    }
  },
  async mounted() {
     await this.getQRCode();
  },
  methods : {
    async getQRCode() {
      store.commit('auth/setAuthRole', "admin")
      let token = this.$store.getters["auth/getToken"];
      let res = null;
      res = await this.$request.post(this.ENDPOINT.UI_HELPER_RESTAURANTS_GET_QR_CODE, {
        restaurant_id : this.$route.params.id
      },{
        headers: {
          Authorization: 'Bearer ' + token
        }
      });
      if (res.hasErrors()) {
          this.msg = this.$t('pages.url_invalid')
      } else {
        this.data = res.data.qr_code;
      }

    }
  }
}
</script>

<style scoped>

</style>
